
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700");

.styles_modal__gNwvD {

	max-width: 1200px;
	min-height: 80%;
}
.LI-simple-link {
	float: right;
}
.LI-icons {
	font-size: 30px;
	color: white;
	background: black;
	padding: 5px 12px;
	border-radius: 5px;
}

body {


  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -------------------------
	Header
---------------------------- */
.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}
.navbar-default .navbar-nav>li>a {
    color: #000;
}

.navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
}
.navbar-default { background-color: white; background: none; box-shadow: none;}
.resume-btn {
	border: 1px solid #333;
    padding: 10px 40px;
}
.lapin-son {
    background: url(./img/lapin-son.svg);
    width: 335px;
    height: 250px;
      z-index: 1;
      float: left;
      background-repeat: no-repeat;
      background-position-x: 75px;
  }
  
  .logo {
      width: 80px; 
      float:Left;
      position: absolute;
      top: 38px;
   }
   .navbar-brand span {
      margin-left: 65px;
      line-height: 30px;
   }
  
   .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #e7e7e7;
}

/* -------------------------
	Recent Work
---------------------------- */
.listLink img {
  -webkit-filter: brightness(40%);
  -moz-filter: brightness(40%);
  -o-filter: brightness(40%);
  -ms-filter: brightness(40%);
  filter: brightness(40%);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.listLink:hover img  {
  -webkit-filter: brightness(100%);
  -moz-filter: brightness(100%);
  -o-filter: brightness(100%);
  -ms-filter: brightness(100%);
  filter: brightness(100%);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.grid-wrap {
	clear: both;
	margin: 0 auto;
	padding: 0;
	max-width: 1260px;
}

.grid {
	margin: 76px auto 62px;
	padding: 0;
	list-style: none;
	min-height: 500px;
}

.js .grid {
	background: url(./img/loading.gif) no-repeat 50% 100px;
}

.js .grid.loaded {
	background: none;
}

.grid li {
	display: inline-block;
	overflow: hidden;
	width:360px;
	text-align: left;
	vertical-align: top;
}

.js .grid li {
	display: none;
	float: left;
}

.js .grid.loaded li {
	display: block;
}

/* Title box */
.title-box h2 {
	display: block;
	margin: 7px;
	padding: 20px;
	background: #2E3444;
	color: #D3EEE2;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
}

.title-box h2 a {
	display: block;
	font-weight: 900;
}

.title-box h2 a:hover {
	color: #D3EEE2;
}

/* Anchor and image */
.grid li > .listLink,
.grid li img {
	display: block;
	outline: none;
	border: none;
}

.grid li > a {
	position: relative;
	overflow: hidden;
}

/* Curtain element */
.grid .curtain {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	background: #96cdc8;
}

.grid.swipe-right .curtain {
	-webkit-transform: translate3d(-100%,0,0);
	transform: translate3d(-100%,0,0);
}

.grid.swipe-down .curtain {
	-webkit-transform: translate3d(0,-100%,0);
	transform: translate3d(0,-100%,0);
}

.grid.swipe-rotate .curtain {
	width: 200%;
	height: 200%;
	-webkit-transform: rotate3d(0,0,1,90deg);
	transform: rotate3d(0,0,1,90deg);
	-webkit-transform-origin: top left;
	transform-origin: top left;
}

/* Shadow */
.grid .curtain::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,1);
	content: '';
}
.grid.swipe-right .curtain::after,
.grid.swipe-rotate .curtain::after {
	left: -100%;
}

.grid.swipe-down .curtain::after {
	top: -100%;
}
.portfolioSlide{
	padding-bottom:116px;
	background-color:#f8f7f8;
	}
/* Title */
.grid li h3 {
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 0;
	padding: 37px 31px 32px;
	width: 100%;
	background: #f8f7f8;
	color: #000;
	text-align: left;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 16px;
	-webkit-transition: -webkit-transform 0.2s, color 0.2s;
	transition: transform 0.2s, color 0.2s;
	line-height:27px;
	z-index:10;
}
.grid li h3 a{
	color: #4c404d;
}
.grid li h3 a:hover{
	text-decoration:none
}
.grid li iframe, .grid li video{
	position:relative;
	z-index:15;
	}

.grid li h3 span{
	display:block;
	font-size:13px;
	color:#000;
	font-weight:normal;
	line-height:21px;
	}

/* Pseudo element for hover effect */
.grid li > .listLink a::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100.5%;
	height: 100.5%;
	z-index:12;
	border: 0px solid transparent;
	background: rgba(0,0,0,0);
	content: '';
	-webkit-transition: border-width 0.2s, border-color 0.2s;
	transition: border-width 0.2s, border-color 0.2s;
}

/* Hover effects */
.grid .masonry li.shown:hover h3 {
	color: #4c404d;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}
/* Hover effects without class shown */
.grid  li:hover h3 {
	color: #4c404d;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}

.grid li.shown:hover > .listLink a::before {
	border-width: 14px;
	/*border-color: rgba(248,247,248,0.8);*/
	border-color:#f8f7f8;
}

/* Animations */

/* Swipe right */
.grid.swipe-right li.animate .curtain {
	-webkit-animation: swipeRight 1.5s cubic-bezier(0.6,0,0.4,1) forwards;
	animation: swipeRight 1.5s cubic-bezier(0.6,0,0.4,1) forwards;
}

@-webkit-keyframes swipeRight {
	0% {} /* fixes Chrome 35.0.1916.114 issue (easing breaks) */
	50%, 60% { -webkit-transform: translate3d(0,0,0); }
	100% { -webkit-transform: translate3d(100%,0,0); }
}

@keyframes swipeRight {
	0% {}
	50%, 60% { -webkit-transform: translate3d(0,0,0); transform: translate(0); }
	100% { -webkit-transform: translate3d(100%,0,0); transform: translate3d(100%,0,0); }
} 

/* Swipe down */
.grid.swipe-down li.animate .curtain {
	-webkit-animation: swipeDown 1.5s cubic-bezier(0.6,0,0.4,1) forwards;
	animation: swipeDown 1.5s cubic-bezier(0.6,0,0.4,1) forwards;
}

@-webkit-keyframes swipeDown {
	0% {} /* fixes Chrome 35.0.1916.114 issue (easing breaks) */
	50%, 60% { -webkit-transform: translate3d(0,0,0); }
	100% { -webkit-transform: translate3d(0,100%,0); }
}

@keyframes swipeDown {
	0% {}
	50%, 60% { -webkit-transform: translate(0); transform: translate(0); }
	100% { transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
}

/* Swipe rotate */
.grid.swipe-rotate li.animate .curtain {
	-webkit-animation: swipeRotate 1.5s ease forwards;
	animation: swipeRotate 1.5s ease forwards;
}

@-webkit-keyframes swipeRotate {
	0% {} /* fixes Chrome 35.0.1916.114 issue (easing breaks) */
	50%, 60% { -webkit-transform: rotate3d(0,0,1,0deg); }
	100% { -webkit-transform: rotate3d(0,0,1,-90deg); }
}

@keyframes swipeRotate {
	0% {}
	50%, 60% { -webkit-transform: rotate3d(0,0,1,0deg); transform: rotate3d(0,0,1,0deg); }
	100% { -webkit-transform: rotate3d(0,0,1,-90deg); transform: rotate3d(0,0,1,-90deg); }
}

/* Shadow */
.grid li.animate .curtain::after {
	-webkit-animation: fadeOut 1.5s ease forwards;
	animation: fadeOut 1.5s ease forwards;
	-webkit-animation-delay: inherit;
	animation-delay: inherit;
}

@-webkit-keyframes fadeOut {
	0% {} /* fixes Chrome 35.0.1916.114 issue (easing breaks) */
	50%, 60% { opacity: 1; }
	100% { opacity: 0; }
}

@keyframes fadeOut {
	0% {}
	50%, 60% { opacity: 1; }
	100% { opacity: 0; }
}

/* Hide image and title and show at half time */
.js .grid li img,
.js .grid li h3,
 .js .grid li iframe{
	visibility: hidden;
}

.grid li.animate img,
.grid li.animate h3 {
	-webkit-animation: showMe 1.5s step-end forwards;
	animation: showMe 1.5s step-end forwards;
}

@-webkit-keyframes showMe {
	from { visibility: hidden; }
	60%, 100% { visibility: visible; }
}

@keyframes showMe {
	from { visibility: hidden; }
	60%, 100% { visibility: visible; }
}

.grid li.shown img,
.grid li.shown h3,
.js .grid li.shown iframe {
	visibility: visible;
}



/* Global Button Styles */
a.animated-button:link, a.animated-button:visited {
	position: relative;
	display: block;
	margin: 30px auto 0;
	padding: 14px 15px;
	color: #fff;
	font-size:14px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	overflow: hidden;
	letter-spacing: .08em;
	border-radius: 0;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}
a.animated-button:link:after, a.animated-button:visited:after {
	content: "";
	position: absolute;
	height: 0%;
	left: 50%;
	top: 50%;
	width: 150%;
	z-index: -1;
	-webkit-transition: all 0.75s ease 0s;
	-moz-transition: all 0.75s ease 0s;
	-o-transition: all 0.75s ease 0s;
	transition: all 0.75s ease 0s;
}
a.animated-button:link:hover, a.animated-button:visited:hover {
	color: #FFF;
	text-shadow: none;
}
a.animated-button:link:hover:after, a.animated-button:visited:hover:after {
	height: 450%;
}
a.animated-button:link, a.animated-button:visited {
	position: relative;
	display: block;
	margin: 30px auto 0;
	padding: 14px 15px;
	color: black;
	font-size:14px;
	border-radius: 0;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	overflow: hidden;
	letter-spacing: .08em;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}
a.animated-button.victoria-four {
	animation: shadow-pulse 1s 3;
	animation-timing-function: linear;
	animation-delay: 1s;
	border: 1px solid black;
	width: 300px;
	
}
a.animated-button.victoria-four:after {
	background: black;  /* fallback for old browsers */	
	opacity: .5;
	-moz-transform: translateY(-50%) translateX(-50%) rotate(90deg);
	-ms-transform: translateY(-50%) translateX(-50%) rotate(90deg);
	-webkit-transform: translateY(-50%) translateX(-50%) rotate(90deg);
	transform: translateY(-50%) translateX(-50%) rotate(90deg);
}
a.animated-button.victoria-four:hover:after {
	opacity: 1;
	height: 600% !important;
}
@keyframes shadow-pulse
{
  0% {
	box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
	box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}
.img-comp-under, .img-comp-over {border-radius: 2% 2% 0 0;}
.lapin-mac-book {
	background: url(./img/LaphinhSMacBook.svg);
	width:100%;
	height: 100%;
	background-repeat: no-repeat;
	margin: 25px 0px;
	padding: 5% 15%;
    background-position-x: -7px;
    background-position-y: 14px;

  }


body {
	font-family: 'Lato';
}
.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
	margin-left: -10px;
	margin-right: -10px;
	padding-right: 10px;
	width: 100%;
}
.row {
	margin-right: -10px;
	margin-left: -10px;
}
.nrp {
	padding-left: 0;
}
.nlp {
	padding-right: 0;
}
.container {
	padding-left: 10px;
	padding-right: 10px;
}
.font-openRegular {
	font-family: 'Lato';
	font-weight: 400;
}
.font-opensemiBold {
	font-family: 'Lato';
	font-weight: 600;
}
.font-openBold {
	font-family: 'Lato';
	font-weight: 100;
	line-height: 54px;
	margin-top: 35px;

}
/* -------------------------
	2.0 Navigation
---------------------------- */
.topNav {
	background-color: #ffffff;
	border-bottom: 0;
	padding: 57px 0 20px;
}
.topNav .nav {
	margin-top: 0;
	margin-right: 30px;
}
.topNav .nav a {
	color: #000;
	padding: 7px 21px;
	font-size: 18px;
	font-weight: 100;
    /* text-transform: uppercase; */

}
.topNav .nav li:last-child a {
	padding-right: 0;
}
.topNav .nav li {
	padding: 0;
	position: relative;
	height: 100%;
}
.topNav .dropdown-menu {
	background-color: #403741;
	color: #cbcbcb;
	position: absolute;
	left: 21px;
	right: auto;
	padding: 4px 0 25px;
	top: 100%;
	border-radius: 0;
	display: block;
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	visibility: hidden;
	opacity: 0;
	transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
}
.topNav .dropdown-menu li {
	line-height: 36px;
	padding: 15px 0 0 0;
}
.topNav .dropdown-menu li a {
	padding: 0 0 0 20px;
}
.topNav .dropdown-menu li:hover > a, .topNav .dropdown-menu > li > a:hover, .topNav .dropdown-menu > li > a:focus {
	background-color: transparent;
	color: #ffffff;
}
.topNav .nav > li:hover > .dropdown-menu {
	visibility: visible;
	opacity: 1;
}
.topNav .nav > li:hover > a {
	color: #333333;
}
.topNav > .container .navbar-brand {
	padding: 0;
	margin: 0;
}
.topNav > .container .navbar-brand span {
    font-size: 22px;
    color: #000;
    padding-left: 22px;
		font-weight: 100;
		line-height: 40px;
}
.navbar-default .navbar-toggle .icon-bar {
	background-color: #403741;
}
.topContent {
	display: grid;
	padding-left: 60px;
	display: inline-block;
}
.socialList {
	padding-right: 0;
}
.topContent h3 {
	font-size: 19px;
	line-height: 27px;
	margin-bottom: 14px;
	color: #4c404d;
}
.topContent h4 {
	color: #000;
	font-size: 20px;
    font-weight: 100;
}
.socialList ul {
	float: right;
	margin-top: 28px;
	text-align: center;
}
.socialList ul li {
	display: inline-block;
	padding: 0 0 0 11px;
}
.socialList ul li a {
	color: #dfdddf;
	font-size: 43px;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}
.socialList ul li a:hover {
	color: #584a59;
}
.menuBottomCon {
	margin-bottom: 57px
}
.separator {
	border-bottom: #e4e3e4 1px solid;
	position: relative;
}
.sepIcon {
	display: block;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #FFF;
	height: 40px;
	width: 80px;
	padding: 0;
	margin: 0 auto -20px;
}
.iconBotom {

  background-image: url(./img/separatorIconbottom.png);
}
.intop {
	background-image: url(./img/separatorIcon.png);
}
/* -------------------------
	3.0 Portfolio
---------------------------- */
.imageSlide {
	text-align: center;
	padding-bottom: 45px;
}
.imageBox {
	background-color: #00aeda;
	width: 74px;
	height: 74px;
	overflow: hidden;
	display: inline-block;
	margin-left: 7px;
	margin-right: 6px;
	vertical-align: top;
	margin-bottom: 13px;
	opacity: 1;
}
.imageBox.activeImage {
	opacity: 1;
	background-color: #00aeda;
	position: relative;
}
.imageBox.activeImage:hover img,
.imageBox.activeImage img{
	opacity:0.5;
}
.imageBox.activeImage:after {
	content: "";
	width: 20px;
	height: 16px;
	background: url(./img/testi-hover.png) no-repeat 0 0;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -8px 0 0 -10px;
}
.fc {
	max-width: 525px;
	margin: 0 auto;
}
.imageSlide.flexslider {
	border: 0;
	background: none;
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
}
.imageSlide.flexslider .flex-direction-nav a {
	display: block;
	font-size: 0.1px;
	text-indent: -99999px;
	width: 12px;
	padding-top: 18px;
	height: 0;
	overflow: hidden;
	margin-top: -14px;
}
.imageSlide.flexslider .flex-direction-nav a.flex-prev {
	background: url(./img/arrow-left.png) 0 0 no-repeat;
}
.imageSlide.flexslider .flex-direction-nav a.flex-next {
	background: url(./img/arrow-right.png) 0 0 no-repeat;
}
.imageSlide.flexslider .flex-direction-nav a:before {
	display: none;
}
.footerTopContent.flexslider {
	margin-left: auto;
	margin-right: auto;
	border: 0;
	background: none;
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
}
.footerTopContent.flexslider {
	border: 0;
	background: none;
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
}
.footerTopContent.flexslider .flex-direction-nav a {
	display: block;
	font-size: 0.1px;
	text-indent: -99999px;
	width: 12px;
	padding-top: 18px;
	height: 0;
	overflow: hidden;
	margin-top: -14px;
}
.footerTopContent.flexslider .flex-direction-nav a.flex-prev {
	background: url(./img/arrow-text-left.png) 0 0 no-repeat;
}
.footerTopContent.flexslider .flex-direction-nav a.flex-next {
	background: url(./img/arrow-text-right.png) 0 0 no-repeat;
}
.footerTopContent.flexslider .flex-direction-nav a:before {
	display: none;
}
.footerTop {
	padding-top: 80px;
	padding-bottom: 84px;
}
.footerTopContent {
	max-width: 620px;
	margin: 0 auto;
	text-align: center;
	position: relative;
}
.footerTopContent li {
	display: block;
	width: 100%;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}
.footerTopContent li.activeTest {
	opacity: 1;
}
.footerTopContent h3 {
	font-size: 19px;
	margin-top: 0;
	color: #4c404d;
	line-height: 27px;
	margin-bottom: 32px;
}
.footerTopContent h4 {
	font-weight: normal;
	color: #898889;
	font-size: 15px;
	line-height: 18px;
}
/* -------------------------
	4.0 Footer part
---------------------------- */
.footer {
	background: #f8f7f8;
	padding-top: 105px;
	font-size: 13px;
	line-height: 18px;
	padding-bottom: 100px;
	text-align: center;
}
.footerLink {
	display: block;
	text-align: center;
	margin-bottom: 19px;
}
.footerLink li {
	display: inline-block;
}
.footerLink li a {
	color: #898889;
	padding: 0 17px;
	text-transform: uppercase;
}
.copyrightText {
	color: #898889;
	margin: 0;
}


/* -------------------------
	Portfolio Part
---------------------------- */
.portfolioList li {
	/*background-color:#f8f7f8;*/
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 21px;
}
.vc {
	display: block;
	line-height: 80px;
	text-align: center;
	font-size: 14px;
	color: #4c404d;
	background-color: #f8f7f8;
	font-weight: bold;
	margin-bottom: 32px;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}
.vc:hover, .vc:focus {
	color: #f8f7f8;
	background-color: #00aeda;
	text-decoration: none;
}
.hiddenClass, .stamp {
	display: none !important;
}
.hiddenElm {
	height: 0;
	width: 0;
	overflow: hidden;
	font-size: 0;
	display: none;
}
.mainImage {
	visibility: hidden !important;
}
.pf-slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.pf-slider img {
	position: absolute;
}
.nav-box {
	position: absolute;
	width: 100%;
	display: block;
	z-index: 100;
	top: 50%;
}
.nav-box span {
	display: block;
	color: #ffffff;
	background-color: #4c404d;
	padding: 0 3px;
	/*margin:0 10px;*/
	text-transform: uppercase;
	cursor: default;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}
.nav-box span:hover, .grid li.shown:hover .nav-box span {
	color: #4c404d;
	background-color: #ffffff;
	margin: 0 10px;
}
.prevLink {
	float: left;
}
.nextLink {
	float: right;
}
.currentSlide {
	z-index: 8 !important;
}
.hiddenElm img {
	visibility: hidden;
}
/* -------------------------
	7.0 Blog part
---------------------------- */
.iconBlog {
	background-image: url(./img/sepIconBlog.png);
}
.iconCont {
	background-image: url(./img/sepIconCon.png);
}
.blogsidebar {
	padding-left: 0;
	padding-right: 0;
}
.blogleft {
	padding-left: 0;
	padding-right: 0;
}
.page-title {
	margin-bottom: 40px;
	font-size: 19px;
	font-family: 'Lato';
	font-weight: bold;
	color: #4c404d;
	text-transform: uppercase;
	letter-spacing: 3px;
	margin-top: -2px;
	margin-left: -10px;
}
.postImage {
	margin-bottom: 38px;
}
.project {
	margin-bottom: 40px;
	/* overflow-y: scroll; */
}
.projectImage {
	margin-bottom: 3px;
}
.blogleft, .blogsidebar {
	padding-bottom: 126px;
}
.entry-title {
	color: #4c404d;
	font-size: 16px;
	font-family: 'Lato';
	font-weight: bold;
	margin-bottom: 11px;
}
.entry-meta {
	color: #a9a9a9;
	font-size: 13px;
	font-family: 'Lato';
	font-style: italic;
	padding-bottom: 37px;
}
.entry-meta span {
	display: block;
	float: left;
	padding-right: 19px;
}
.entry-content p {
	color: #898889;
	font-size: 14px;
	line-height: 27px;
	margin-bottom: 44px;
}
.entry-content p:last-child {
	margin-bottom: 0;
}
.more-link {
	display: inline-block;
	padding: 0 20px;
	font-size: 14px;
	line-height: 46px;
	border: #4c404d 1px solid;
	color: #4c404d;
	font-size: 14px;
	font-weight: bold;
	letter-spacing:3px;
	text-transform: uppercase;
}
.more-link:hover, .more-link:focus {
	color: #ffffff;
	text-decoration: none;
	background-color: #4c404d;
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
}
.post {
	padding-bottom: 91px;
}
.postnav {
	padding: 27px 0 0;
}
.postnav a {
	display: block;
	text-transform: uppercase;
	font-size: 14px;
	color: #4c404d;
	letter-spacing:2px;
	font-weight: bold;
}
.postnav a:hover, .postnav a:focus {
	color: #4c404d;
	text-decoration: underline;
}
.post-prev {
	float: left;
	background: url(./img/pl.png) 0 50% no-repeat;
	padding: 0 0 0 30px;
}
.post-next {
	float: right;
	background: url(./img/pr.png) 100% 50% no-repeat;
	padding: 0 30px 0 0;
}
.widget {
	padding-bottom: 61px;
	margin: 14px;
}
.sidebarTitle {
	margin-top: -2px;
	font-family: 'Lato';
	font-weight: bold;
	font-size: 14px;
	text-transform:uppercase;
	letter-spacing:2px;
	margin-bottom: 18px;
}
.sidebarprojecttitle {
	color: #000;
	margin-top: -2px;
	font-family: 'Lato';
	font-weight: bold;
	font-size: 18px;
	text-transform:uppercase;
	letter-spacing:2px;
	margin-bottom: 14px;
}
.sidebarEntry {
    color: #000;
    margin-top: -2px;
    font-family: 'Lato';
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 38px;
		font-weight: 300;
	  width:90% }
.sidebarEntry a {
	color: #FF5E56;
	margin-top: -2px;
	font-family: 'Lato';
	font-size: 14px;
	margin-bottom: 38px;
}
.widget ul li {
	line-height: 38px;
	font-size: 14px;
	font-family: 'Lato';
}
.widget li a {
	color: #898889;
}
.showSlide {
	position: relative;
	width: 100%;
	overflow: hidden;
}



/* -------------------------
	Responsive part
---------------------------- */
@media (max-width: 960px) {

	.bodyContent {padding: 0 40px;}
	.topContent {
		width: 100%;
	}
	.grid {
		margin: 76px auto 0;
	
	}
	.portfolioList li {
	
		margin-left: 5px;
		margin-right: 5px;

	}
}
@media (max-width: 767px) {
	.navbar-right {
		float: right!important;
		margin-right: -15px;
	}
.navbar-collapse.collapse {
    display: block!important;
    height: auto!important;
    padding-bottom: 0;
	overflow: visible!important;
}
.navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.navbar-nav {
    float: left;
    margin: 0;
}
.topNav .nav {
	background:#403741;
}
.bodyContent {padding: 0 40px;}
.topNav .nav a,
.navbar-default .navbar-nav > li > a{
	color:#e8e8e8;
}
.topNav .nav a {
	padding: 5px 21px;
}
.topNav .nav li {
	overflow: hidden;
	height: 100%;
}
.topNav .dropdown-menu {
	position: static;
	background-color: #1c181c;
	display: block;
	visibility: visible;
	opacity: 1;
	width: 100%;
	border: 0;
	box-shadow: none;
	-moz-box-shadow: none;
}
.topNav .dropdown-menu li {
	padding-left: 21px;
}
.topNav .nav > li:hover > a, .topNav .dropdown-menu li:hover > a, .topNav .dropdown-menu > li > a:hover, .topNav .dropdown-menu > li > a:focus {
	background-color: transparent;
	color: #fdf5fe;
}
.navbar-header {
	padding-bottom: 15px;
}
.topNav > .container .navbar-brand {
	margin-left: 15px;
}
.socialList {
	padding-left: 0;
}
.socialList ul {
	display: block;
	float: none;
}
.socialList ul li {
	padding: 0 6px 0 6px;
}
.portfolioList li {
    margin-left: 50px;
    margin-right: 5px;
}

.vc, .separator {
	margin-left: 10px;
	margin-right: 10px;
}
.page-title {
	padding-left: 10px;
}
.blogleft, .blogsidebar, .cl, .cr, .contactSide {
	padding-left: 10px;
	padding-right: 10px;
}
.cl {
	padding-bottom: 48px;
}
.pvalue {
	margin-top: 2px;
	padding: 0;
}
}
